import { useEffect, useState } from "react";

export interface ExpiredPasswordDialogProps {
    children?: JSX.Element;
    visible: boolean;
    email: string;
}

export const useExpiredPasswordDialogState = (props:ExpiredPasswordDialogProps) => {
    const [isOpen, setOpen] = useState(false);

    const closeDialog = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (props.visible) {
            setOpen(true);
        }
    }, [props.visible]);

    return {
        isOpen,
        closeDialog,
    }
}