import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import { NextLinkComposed } from 'src/components/common/next-link-composed';
import { Route } from 'src/constants/ui';
import { encodeBase64 } from 'src/utils/decode-base64';

import { ExpiringPasswordDialogProps, useExpiringPasswordDialogState } from './expiring-password-dialog.state';

 

export const ExpiringPasswordDialog: React.FC<ExpiringPasswordDialogProps> = (props) => {
    const state = useExpiringPasswordDialogState(props);

    return (<Dialog open={state.isOpen}>
        <Dialog.Content
            className="text-center px-2 py-5 sm:px-10 sm:py-5 w-[90%] sm:w-full rounded" aria-labelledby="scroll-dialog-title">
            <Stack>
                <Icon name="BigExclamation" className="text-black-10 mx-auto" />
                <div className="pt-6 pb-4 font-medium"> Password Expires in 7 days </div>
                <div className=" items-center">
                    Your password expires soon. Do you want to reset it now?
                </div>

                <div className="pt-10 flex flex-nowrap justify-center items-center space-x-2">
                    <Button className="sm:w-40  " onClick={state.postponePasswordUpdate} variant='outline'>
                        I&apos;ll do this later
                    </Button>

                    <NextLinkComposed to={{ pathname: Route.FORGOT_PASSWORD, query: { email: encodeBase64(props.email) } }}>
                        <Button>
                            Reset my password
                        </Button>
                    </NextLinkComposed>
                </div>
            </Stack>
        </Dialog.Content>
    </Dialog>
    );
}


