import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import { getMaxUnitOfTime, getPrettyCountdownTime } from 'src/utils/get-pretty-countdown-time';

import { UserLockUserDialogProps, useUserLockUserDialogState } from './lockout-user-dialog.state';

export const LockoutUserDialog: React.FC<UserLockUserDialogProps> = (props) => {
    const state = useUserLockUserDialogState(props);

    return (props.lockoutSeconds > 0 &&
            <Dialog open={state.isOpen} >
                <Dialog.Content 
                    className="text-center px-10 py-5 w-[90%] sm:w-full rounded" aria-labelledby="scroll-dialog-title">
                    <Stack>
                        <Icon name="BigExclamation" className="text-black-10 mx-auto" />
                        <div className="pt-8 pb-4 font-medium" > We couldn&apos;t log you in</div>
                        <div className=" items-center">
                            To protect your account, it has been locked due to consecutive failed attempts.
                            {props.lockoutSeconds >= 86400 ? " Please try again." : (
                                <> Please try again in <span className="font-bold"> {getPrettyCountdownTime(state.count)} </span> {getMaxUnitOfTime(state.count)}.</>
                            )}
                        </div>

                        <div className="pt-10">
                            <Button className="w-40" onClick={state.closeDialog}>
                                Ok
                            </Button>
                        </div>
                    </Stack>
                </Dialog.Content>
            </Dialog> 
    );
}


