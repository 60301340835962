import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from 'components/dist/atoms/Button';
import Checkbox from 'components/dist/atoms/Checkbox';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Label from 'components/dist/atoms/Label';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import PasswordInput from "components/dist/molecules/PasswordInput";
import { format } from 'date-fns';
import { FormikHelpers, useFormik } from 'formik';
import { useRouter } from "next/router";
import { FC, useContext, useEffect, useState } from "react";
import { NextLinkComposed } from 'src/components/common/next-link-composed';
import { ExpiredPasswordDialog } from 'src/components/expired-password-dialog';
import { ExpiringPasswordDialog } from 'src/components/expiring-password-dialog';
import { LockoutUserDialog } from 'src/components/lockout-user-dialog/lockout-user-dialog.component';
import { HttpHeader, Route } from "src/constants/ui"
import { AuthContext } from "src/contexts/auth-context";
import { useSubdomainLender } from "src/contexts/subdomain-lender-context";
import { useAuth } from 'src/hooks/use-auth';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';
import { toast } from "src/utils/toast";

import { CompanyLogo } from "../company-logo";
import { SocialAuthButtons } from '../social-auth-buttons';
import { loginFormValidationSchema } from "./login-form-validation";
import { URlAlertMessage } from './url-alert-message';

interface LoginFormValues {
    password: string,
    username: string,
    remember: boolean,
}

type Props = {
    showLogo?: boolean;
    showSignupLink?: boolean;
    title?: string;
    onLoginSuccess?: () => void;
    setTabSelected?: (tab: 'login' | 'signup' | 'reset') => void;
    defaultValues?: Partial<LoginFormValues>;
};

export const LoginForm: FC<Props> = ({
    showLogo = true,
    showSignupLink = true,
    title = "Log in to your account",
    onLoginSuccess,
    setTabSelected,
    defaultValues
}) => {
    const { login } = useContext(AuthContext);
    const [lockoutSeconds, setLockoutSeconds] = useState(0);
    const [isPasswordExpired, setIsPasswordExpired] = useState(false);
    const [isPasswordExpiring, setIsPasswordExpiring] = useState(false);
    const { query, push } = useRouter();
    const { logo, name } = useSubdomainLender();
    const auth = useAuth();
    // Either the base64 logo url or false
    const queryEmail = query?.email ? String(query.email) : '';

    const initialValues: LoginFormValues = {
        password: '',
        username: queryEmail,
        remember: false,
        ...defaultValues,
    }
    const formik = useFormik({
        validationSchema: loginFormValidationSchema,
        initialValues,
        onSubmit: async (values: LoginFormValues, formikHelpers: FormikHelpers<LoginFormValues>) => {
            formikHelpers.setSubmitting(true);
            try {
                setIsPasswordExpired(false);
                setIsPasswordExpiring(false);
                const result = await login({
                    username: String(values.username).trim(),
                    password: String(values.password).trim(),
                    remember: values.remember,
                    timestamp: format(new Date(), 'HH:mm:ss'),
                });

                if (result?.success && auth.user?.isPasswordExpiring) {
                    setIsPasswordExpiring(true);
                } else {
                    formikHelpers.setSubmitting(false);
                    if (result?.challenge == "NEW_PASSWORD_REQUIRED") {
                        await push({ pathname: Route.NEW_PASSWORD, query: { session: result.session } });
                    }
                }
            } catch (error) {
                console.error('Login error', error)
                formikHelpers.setSubmitting(false);
                if (error.headers?.[HttpHeader.MY_SHERPAS_LOCKED_USER]) {
                    setLockoutSeconds(error.headers[HttpHeader.MY_SHERPAS_REMAINING_LOCKED_SECONDS]);
                } else if (error.headers?.[HttpHeader.MY_SHERPAS_PASSWORD_EXPIRED]) {
                    setIsPasswordExpired(error.headers[HttpHeader.MY_SHERPAS_PASSWORD_EXPIRED]);
                } else {
                    toast({
                        type: 'error',
                        content: 'Incorrect user name or password. Please try again or contact support',
                    });
                }

                formikHelpers.setErrors({
                    username: '',
                    password: error.headers?.[HttpHeader.MY_SHERPAS_ONE_LOGIN_ATTEMPT_LEFT] ? 'You have 1 log in attempt left' : '',
                });
                notifyBugTracker(error);
            }
        },
    });

    useEffect(() => {
        if (auth.user) {
            if (auth.user.isPasswordExpiring) {
                setIsPasswordExpiring(true);
            }
        }

    },
        [auth.user],
    );

    return (
        <form
            className="w-full max-w-md"
            noValidate
            onSubmit={formik.handleSubmit}>
            <URlAlertMessage />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                <div className="gap-6 flex flex-col">
                    {showLogo && (<CompanyLogo name={name} logo={logo} />)}
                    <Text size="xl" weight="medium">
                        {title}
                    </Text>
                </div>
            </Box>
            <SocialAuthButtons mode='login' />
            <Divider sx={{ mt: 4, color: themeV2.neutral.grey[100], fontSize: 14, borderColor: themeV2.neutral.grey[80] }}>OR</Divider>
            <Stack space="lg" className="mt-10">
                <Stack space="sm">
                    <Label variant="secondary" htmlFor="username">
                        Email Address<Text as="span" size="sm" variant="blue">*</Text>
                    </Label>
                    <Input
                        id='username'
                        name='username'
                        placeholder="Email Address"
                        data-testid="username"
                        disabled={!!queryEmail}
                        autoComplete='username'
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                    />
                    {(formik.touched.username && formik.errors.username) &&
                        <Text as="div" className="flex items-center gap-1" variant="destructive" size="xs">
                            <Icon name="InfoEmpty" width={12} height={12} strokeWidth={1.5} />
                            {formik.errors.username}
                        </Text>
                    }
                </Stack>
                <Stack space="sm">
                    <Label variant="secondary" htmlFor="password">
                        Password<Text as="span" size="sm" variant="blue">*</Text>
                    </Label>
                    <PasswordInput
                        id='password'
                        name='password'
                        data-testid="password"
                        placeholder='Enter Password'
                        autoComplete='current-password'
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <Text as="div" className="flex items-center gap-1" variant="destructive" size="xs">
                            <Icon name="InfoEmpty" width={12} height={12} strokeWidth={1.5} />
                            {formik.errors.password}
                        </Text>
                    )}
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <Label htmlFor="remember" className='flex items-center gap-2'>
                        <Checkbox
                            id="remember"
                            name='remember'
                            onCheckedChange={(checked) => formik.setFieldValue('remember', checked)}
                            checked={formik.values.remember}
                        /> Remember me
                    </Label>
                    {!!setTabSelected && (
                        <Button
                            size="sm"
                            variant="link"
                            onClick={() => setTabSelected('reset')}
                        >
                            Forgot Password ?
                        </Button>
                    )}
                    {!setTabSelected && (
                        <Button
                            size="sm"
                            variant="link"
                        >
                            <NextLinkComposed to={{ pathname: Route.FORGOT_PASSWORD }}>
                                Forgot Password ?
                            </NextLinkComposed>
                        </Button>
                    )}
                </Box>
                <Button
                    data-testid='login-button'
                    loading={formik.isSubmitting}
                    type='submit'>
                    Login
                </Button>
                {showSignupLink && (
                    <Text
                        size="sm"
                        className="mt-6 gap-2 flex items-center justify-center"
                        weight="medium">
                        New User ?
                        <Text size="sm" asChild variant="blue" as="span" weight="medium">
                            <NextLinkComposed to={{ pathname: Route.SIGNUP, query: { url: query.url } }}>
                                Register
                            </NextLinkComposed>
                        </Text>
                    </Text>
                )}
            </Stack>
            {lockoutSeconds > 0 && <LockoutUserDialog lockoutSeconds={lockoutSeconds} />}
            {formik.values.username && isPasswordExpired && <ExpiredPasswordDialog visible={isPasswordExpired} email={formik.values.username.trim()} />}
            {formik.values.username && isPasswordExpiring && <ExpiringPasswordDialog visible={isPasswordExpiring} onLoginSuccess={onLoginSuccess} email={formik.values.username.trim()} />}
        </form>
    )
};

export default LoginForm;