import * as Yup from 'yup';

export const loginFormValidationSchema = Yup
    .object()
    .shape({
        username: Yup
            .string()
            .min(3, 'Must be at least 3 characters')
            .max(255)
            .required('Required field'),
        password: Yup
            .string()
            .required('Required field'),
        remember: Yup
            .boolean()

    })
