import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Route } from "src/constants/ui";

export interface ExpiringPasswordDialogProps {
    children?: JSX.Element;
    visible: boolean;
    email: string;
    onLoginSuccess?: () => void;
}
export const useExpiringPasswordDialogState = (props:ExpiringPasswordDialogProps) => {
    const [isOpen, setOpen] = useState(false);
    const { query, push } = useRouter();

    const postponePasswordUpdate = () => {
        if (query.url) {
            window.location.href = decodeURIComponent(query.url as string);
        } else if (props.onLoginSuccess) {
            props.onLoginSuccess();
        } else {
            push({ pathname: Route.HOME });
        }

        setOpen(false);
    };

    useEffect(() => {
        if (props.visible) {
            setOpen(true);
        }
    }, [props.visible]);

    return {
        isOpen,
        postponePasswordUpdate,
    }
}