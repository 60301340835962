import CloseIcon from '@mui/icons-material/Close';
import type { AlertColor } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { useRouter } from "next/router"
import { useEffect, useState } from "react";
import { decodeBase64 } from "src/utils/decode-base64";

export const URlAlertMessage = () => {
    const [sanitizedMessage, setSanitizedMessage] = useState('');
    const [open, setOpen] = useState(true);
    const { query: { status, message } } = useRouter();
    const severity: AlertColor = ['success', 'info', 'warning', 'error'].includes(status as AlertColor) ? String(status) as AlertColor : 'info';

    useEffect(() => {
        (async () => {
            const sanitizeHtml = (await import('sanitize-html')).default;
            setSanitizedMessage(sanitizeHtml(decodeBase64(String(message))));
        })();
    }, [message]);

    if (!message) {
        return null;
    }

    const handleClick = (event) => {
        event.preventDefault();
        if (event.target.tagName.toUpperCase() === 'A') {
            window.open(event.target.href, '_blank');
            //close current window
            window.close();
        }
    }

    return (<Collapse in={open}><Alert
        action={
            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    setOpen(false);
                }}
            >
                <CloseIcon fontSize="inherit" />
            </IconButton>
        }
        variant="filled" severity={severity} sx={{ mb: 4, textTransform: 'capitalize' }}>
        <AlertTitle>{status}</AlertTitle>
        <Box
            onClick={handleClick}
            sx={{
                '& a': {
                    color: 'white',
                    borderRadius: .5,
                    mx: .5,
                    padding: '3px 4px',
                    backgroundColor: 'error.main',
                    display: 'inline-block',
                    textDecoration: 'none',
                    '&:hover': {
                        color: 'inherit',
                        backgroundColor: 'error.dark',
                    },
                }
            }}
            dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
    </Alert>
    </Collapse>)
}

